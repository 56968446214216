<template>
  <div
    :class="stateClasses"
    class="ui-input-select"
    data-testid="elem_vrp_000919"
    @click="changeFocus(true)"
    @focus="changeFocus(true)"
    @blur="changeFocus(false)"
    v-click-outside="onClickOutside"
  >
    <div class="ui-input-select__area" data-testid="elem_vrp_000920">
      <label v-if="(label || $slots.label) && !hideLabel" :for="fieldId" class="ui-input-select__label" data-testid="elem_vrp_000921">
        <slot name="label">
          {{ label }}
        </slot>
      </label>

      <div class="ui-input-select__field-area" data-testid="elem_vrp_000922">
        <input
          ref="inputElement"
          :id="fieldId"
          :type="inputType"
          :disabled="disabled"
          :class="{
            '--with-action': true
          }"
          class="ui-input-select__field"
          :value="fieldSearch.value.value"
          v-bind="$attrs"
          data-testid="elem_vrp_000923"
          @input="fieldSearch.handleChange($event, false)"
          @change="fieldSearch.handleChange($event, false)"
          @focus="changeFocus(true, $event)"
          @blur="changeFocus(false, $event)"
        />
      </div>

      <ui-icon
        :name="isShowSelectMenu ? 'Linear/Arrows/Alt Arrow Up' : 'Linear/Arrows/Alt Arrow Down'"
        :size="20"
        class="ui-input-select__append-icon"
        @click.stop="toggleSelectMenu"
      />

      <div
        v-show="isVisibleMenu"
        class="ui-input-select__menu"
        data-testid="elem_vrp_000924"
      >
        <template v-if="resultItems.length">
          <div
            v-for="item in resultItems"
            :key="objected ? item[itemKey] : item"
            role="button"
            class="ui-input-select__menu-item"
            data-testid="elem_vrp_000925"
            @click.stop="setSelectValue(item)"
          >
            <span class="ui-input-select__menu-item-text" data-testid="elem_vrp_000926">
              <slot :item="item" name="list-item">
                {{ objected ? item[itemLabel] : item }}
              </slot>
            </span>
          </div>
        </template>
        <div v-else class="ui-input-select__menu-item" data-testid="elem_vrp_000925">
          <span class="ui-input-select__menu-item-text --muted" data-testid="elem_vrp_000926">
            No results
          </span>
        </div>
      </div>
    </div>

    <div v-if="helpText || $slots.helpText" class="ui-input-select__help" data-testid="elem_vrp_000927">
      <p class="ui-input-select__help-item" data-testid="elem_vrp_000928">
        <slot name="helpText">
          {{ helpText }}
        </slot>
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate'

interface Props {
  path: string
  pathSearch: string
  containerClass?: string
  label?: string
  required?: boolean
  hideLabel?: boolean
  disabled?: boolean
  helpText?: string
  objected?: boolean
  items: string[] | any[]
  itemKey?: string
  itemLabel?: string
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  containerClass: '',
  hideLabel: false,
  required: false,
  disabled: false,
  helpText: '',
  objected: false,
  items: () => ([]),
  itemKey: '',
  itemLabel: '',
})

const {
  label,
  containerClass,
  hideLabel,
  required,
  disabled,
  helpText,
  items,
  path,
  pathSearch,
  objected,
  itemKey,
  itemLabel,
} = toRefs(props)

const emit = defineEmits<{ change: [unknown] }>()

const fieldId = useId()

const fieldSearch = useField<string>(pathSearch.value, undefined, { syncVModel: true })
const resultItems = computed(() => {
  if (!fieldSearch.value) {
    return items.value
  }
  if (objected.value) {
    const candidate = items.value.find((item) => item[itemLabel.value].toLowerCase() === fieldSearch.value.value?.toLowerCase()?.trim()) || null
    if (candidate) {
      return items.value
    }
    return items.value.filter((item) => {
      return (item[itemLabel.value].toLowerCase()).includes(fieldSearch.value.value?.toLowerCase()?.trim())
    })
  }

  const candidate = items.value.find((item) => item.toLowerCase() === fieldSearch.value.value?.toLowerCase()?.trim())
  if (candidate) {
    return items.value
  }
  return items.value.filter((item) => {
    return (item.toLowerCase()).includes(fieldSearch.value.value?.toLowerCase()?.trim())
  })
})

const isShowSelectMenu = ref(false)

const toggleSelectMenu = () => {
  if (disabled.value) {
    return
  }
  isFocus.value = true
  isShowSelectMenu.value = !isShowSelectMenu.value
}

const field = useField<string>(path.value, undefined, { syncVModel: true })

const setFieldSearchByField = () => {
  if (field.value.value && items.value.length) {
    if (objected.value) {
      const item = items.value.find((item) => item[itemKey.value] === field.value.value)
      if (item && fieldSearch.value.value !== item[itemLabel.value]) {
        fieldSearch.setValue(item[itemLabel.value], false)
      }
    } else {
      const item = items.value.find((item) => item === field.value.value)
      if (fieldSearch.value.value !== item) {
        fieldSearch.setValue(item, false)
        field.setValue(item, false)
      }
    }
  } else {
    fieldSearch.setValue('', false)
  }
}

watch(field.value, (value, oldValue) => {
  if (value === oldValue) {
    return
  }
  setFieldSearchByField()
  emit('change', value)
}, { deep: true })

const inputElement = ref(null)
const isFocus = ref(false)
const changeFocus = (value: boolean, evt: Event | null = null) => {
  if (isVisibleMenu.value && evt?.type === 'blur') {
    return
  }
  if (disabled.value) {
    return
  }

  isFocus.value = value

  if (value) {
    field.setTouched(true)
    nextTick(() => {
      isShowSelectMenu.value = true
    })
  }
  if (evt && !value) {
    fieldSearch.handleBlur(evt, true)
    isShowSelectMenu.value = false
  }
}

const isVisibleMenu = computed(() => {
  return items.value.length && isFocus.value && isShowSelectMenu.value
})

const stateClasses = computed(() => {
  return {
    '--focused': isFocus.value,
    '--active': import.meta.server || !!fieldSearch.value.value || isFocus.value,
    '--disabled': disabled.value,
    '--required': required.value,
    '--with-errors': !!field.errors.value.length,
    '--with-help-text': !!helpText.value,
    '--with-menu': isVisibleMenu.value,
    [containerClass.value]: true,
  }
})

const inputType = computed(() => {
  return 'text'
})

const setSelectValue = (item: string | any) => {
  if (!item) {
    return
  }
  if (!objected.value) {
    fieldSearch.setValue(item, false)
    field.setValue(item, true)
    toggleSelectMenu()
    return
  }
  fieldSearch.setValue(item[itemLabel.value] as string, false)
  field.setValue(item[itemKey.value] as string, true)
  toggleSelectMenu()
}

const onClickOutside = async () => {
  setFieldSearchByField()
  changeFocus(false)
  isShowSelectMenu.value = false
}

if (field.value.value && items.value.length) {
  if (objected.value) {
    const candidate = items.value.find((item) =>  item[itemKey.value] === field.value.value)
    if (candidate && itemKey.value in candidate) {
      setSelectValue(candidate)
    }
  } else {
    setSelectValue(field.value.value)
  }
  isFocus.value = false
  isShowSelectMenu.value = false
}
</script>

<style lang="scss">
.ui-input-select {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;

  &__area {
    padding: 16rem 14rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 56rem;
    height: 56rem;
    background: map-get($grayPalette, 'color_28');
    border-radius: 10rem;
  }

  &__label {
    user-select: none;
    color: map-get($grayPalette, 'color_9b');
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24rem;
    background: transparent;
    transition: all 200ms;
  }

  &__field-area {
    width: 100%;
    display: none;
    opacity: 0;
    transition: all 200ms;
    justify-content: space-between;
    align-items: center;
    gap: 0 8rem;
  }

  &__field {
    width: 100%;
    color: map-get($grayPalette, 'color_ff');
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24rem;
    background: transparent;
    border: unset;
    outline: unset;
    box-shadow: unset;

    &.--with-action {
      width: calc(100% - 28rem);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px map-get($grayPalette, 'color_28') inset !important;
      -webkit-text-fill-color: map-get($grayPalette, 'color_ff') !important;
      -webkit-background-clip: text;
      -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
      -webkit-transition-delay: 9999s;
      caret-color: white;
      transition: background-color 5000s ease-in-out 0s;
    }

    &:-webkit-autofill::first-line {
      font-size: 16rem;
    }
  }

  &__append-icon {
    cursor: pointer;
    position: absolute;
    right: 14rem;
    top: 18rem;
    bottom: 18rem;
    font-size: 20rem;
    color: map-get($grayPalette, 'color_9b') !important;
  }

  &__error-item {
    margin: 2rem 14rem 0;
    color: map-get($colorPalette, 'color_ff6');
    font-size: 14rem;
    font-weight: 400;
    line-height: 22rem;
    letter-spacing: 0.28rem;
  }

  &__help-item {
    margin: 2rem 14rem 0;
    color: map-get($grayPalette, 'color_9b');
    font-size: 14rem;
    font-weight: 400;
    line-height: 22rem;
    letter-spacing: 0.28rem;
  }

  &__menu {
    content: '';
    padding: 6rem;
    max-height: 252rem;
    position: absolute;
    top: calc(100%);
    left: 0;
    right: 0;
    background: map-get($grayPalette, 'color_28');
    border-radius: 0 0 10rem 10rem;
    overflow-y: auto;
    z-index: 1;

    &::-webkit-scrollbar {
      width: 2px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5rem;
      background: map_get($grayPalette, "color_9b");
    }

    &::-webkit-scrollbar-track {
      border-radius: 5rem;
      background: map_get($grayPalette, "color_28");
    }
  }

  &__menu-item {
    cursor: pointer;
    padding: 8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10rem;

    &:hover {
      background: map-get($grayPalette, 'color_36');
    }
  }

  &__menu-item-text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: map-get($grayPalette, 'color_ff');
    font-size: 16rem;
    font-weight: 400;
    line-height: 24rem;

    &.--muted {
      color: map-get($grayPalette, 'color_9b');
    }
  }

  &.--focused,
  &.--active {
    .ui-input-select__label {
      font-size: 12rem;
      line-height: 16rem;
      letter-spacing: 0.24rem;
    }
    .ui-input-select__field-area {
      opacity: 1;
      display: flex;
    }
  }

  &.--disabled {
    cursor: default;

    .ui-input-select__label {
      color: map-get($grayPalette, 'color_62');
    }
    .ui-input-select__field {
      cursor: default;
      color: map-get($grayPalette, 'color_62');
    }
  }

  &.--with-errors {
    .ui-input-select__area {
      border: 1px solid map-get($colorPalette, 'color_ff6');
    }
    .ui-input-select__field {
      color: map-get($colorPalette, 'color_ff6');
    }
  }

  &.--with-errors:not(.--active) {
    .ui-input-select__label {
      color: map-get($colorPalette, 'color_ff6');
    }
  }

  &.--required {
    .ui-input-select__label {
      position: relative;

      &::after {
        content: '*';
        padding-left: 6rem;
        display: inline-block;
        color: map-get($colorPalette, 'color_ff6');
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24rem;
        transition: font-size 200ms;
      }
    }
  }

  &.--focused.--required,
  &.--active.--required {
    .ui-input-select__label {
      &::after {
        font-size: 12rem;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.24rem;
      }
    }
  }

  &.--disabled.--required {
    .ui-input-select__label {
      &::after {
        color: map-get($grayPalette, 'color_62');
      }
    }
  }

  &.--with-menu {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-top: 1rem solid map-get($grayPalette, 'color_36');
    }
    .ui-input-select__area {
      border-radius: 10rem 10rem 0 0;
    }
  }
}
</style>
